var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-product-sidebar","centered":"","visible":_vm.isEditProductSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"ok-only":""},on:{"ok":function($event){return _vm.$refs.submitButtonRef.click()},"hidden":function($event){_vm.formValidation(_vm.resetblankproduct).resetForm},"change":function (val) { return _vm.changed(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n        sticky-top\n      "},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("companyProductEdit"))+" ")]),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","size":"sm","type":"submit"},on:{"click":function($event){return _vm.$refs.editForm.click()}}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"SaveIcon"}})],1),_c('feather-icon',{staticClass:"ml-1 cursor-pointer text-black",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"formValidation(\n    resetblankproduct\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('b-button',{ref:"editForm",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('code'),"label-for":"kod"}},[_c('b-form-input',{attrs:{"id":"kod","autofocus":"","state":_vm.formValidation(_vm.resetblankproduct).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankProductData.kod),callback:function ($$v) {_vm.$set(_vm.blankProductData, "kod", $$v)},expression:"blankProductData.kod"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":_vm.formValidation(_vm.resetblankproduct).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankProductData.ingredientname),callback:function ($$v) {_vm.$set(_vm.blankProductData, "ingredientname", $$v)},expression:"blankProductData.ingredientname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('type'),"label-for":"type"}},[_c('v-select',{staticClass:"w-100",attrs:{"transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.cins,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankProductData.cins),callback:function ($$v) {_vm.$set(_vm.blankProductData, "cins", $$v)},expression:"blankProductData.cins"}})],1),_c('b-card-actions',{attrs:{"title":_vm.$t('values'),"action-collapse":"","collapsed":false}},_vm._l((_vm.blankProductData.values),function(value){return _c('div',{key:value.id,attrs:{"rules":"required","name":"fiyat"}},[_c('b-form-group',{attrs:{"label":value.nutrientname,"label-for":"fiyat"}},[_c('BInputGroup',[_c('b-form-input',{attrs:{"id":value.nutrientname,"step":"any","type":"number","trim":"","placeholder":""},model:{value:(value.deger),callback:function ($$v) {_vm.$set(value, "deger", $$v)},expression:"value.deger"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('span',[_vm._v(_vm._s(value.birim))])])],1)],1)],1)}),0)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }