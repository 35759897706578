<template>
  <b-modal
    id="edit-product-sidebar"
    centered
    :visible="isEditProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    @ok="$refs.submitButtonRef.click()"
    @hidden="formValidation(resetblankproduct).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
          sticky-top
        "
      >
        <h5 class="mb-0">
          {{ $t("companyProductEdit") }}
        </h5>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            type="submit"
            @click="$refs.editForm.click()"
          >
            <feather-icon class="text-primary" icon="SaveIcon" />
          </b-button>

          <feather-icon
            class="ml-1 cursor-pointer text-black"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankproduct
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <b-button ref="editForm" type="submit" class="d-none" />
          <!-- Kod -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('code')" label-for="kod">
              <b-form-input
                id="kod"
                v-model="blankProductData.kod"
                autofocus
                :state="
                  formValidation(resetblankproduct).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="name"
          >
            <b-form-group :label="$t('name')" label-for="name">
              <b-form-input
                id="name"
                v-model="blankProductData.ingredientname"
                autofocus
                :state="
                  formValidation(resetblankproduct).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group :label="$t('type')" label-for="type">
            <v-select
              v-model="blankProductData.cins"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cins"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <!-- Values -->
          <b-card-actions
            :title="$t('values')"
            action-collapse
            :collapsed="false"
          >
            <!-- Values -->
            <div
              v-for="value in blankProductData.values"
              :key="value.id"
              rules="required"
              name="fiyat"
            >
              <b-form-group :label="value.nutrientname" label-for="fiyat">
                <BInputGroup>
                  <b-form-input
                    :id="value.nutrientname"
                    v-model="value.deger"
                    step="any"
                    type="number"
                    trim
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <span>{{ value.birim }}</span>
                  </b-input-group-append>
                </BInputGroup>
              </b-form-group>
            </div>
          </b-card-actions>
          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditProductSidebarActive",
    event: "update:is-edit-product-sidebar-active",
  },
  props: {
    isEditProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankProductData: {},
      cins: [
        {
          label: this.$t("grain"),
          value: 2,
        },
      ],
      formValidation: formValidation,
    };
  },

  methods: {
    async fetchNewIngredient() {
      var { data } = await this.$http.get(
        `/Ingredients/GetProductValuesById/${this.product.item.id}`,
        {
          headers: {
            culture: getUserData().cultureinfo,
            tip: "0",
            plantid: "0",
          },
        }
      );
      this.blankProductData = this.product.item;
      this.blankProductData.values = data.resultdata;
      this.blankProductData.cins = 2;
    },
    async changed(val) {
      if (val == true) await this.fetchNewIngredient();
      this.$emit("update:is-edit-product-sidebar-active", val);

      this.resetblankproduct();
    },
    onSubmit() {
      var ingredient = {};
      var values = [];
      ingredient.id = this.blankProductData.id;
      ingredient.cins = this.blankProductData.cins;
      ingredient.ingredientname = this.blankProductData.ingredientname;
      ingredient.kod = this.blankProductData.kod;

      this.blankProductData.values.map((x) => {
        if (x.deger != 0) {
          values.push({
            id: x.id,
            deger: parseFloat(x.deger),
          });
        }
      });
      store
        .dispatch("companyProductsModule/updateCompanyProduct", {
          ingredient,
          values,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-product-sidebar-active", false);
        });
    },
    async resetblankproduct() {
      this.blankProductData = await this.fetchNewIngredient;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-product-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
